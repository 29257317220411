@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdn.syncfusion.com/ej2/material.css");

:root {
  scroll-behavior: smooth;
  --primary: #8b313a;
  --secondary: #425466;
  --secondary2: #31778c;
  --secondary3: #eaf1f4;
  --secondary4: #a8d4e1;
  --secondary3: rgba(49, 119, 140, 0.1);
  --blacklight: #525252;
  --gray: #f1f1f1;
  --gray2: #e5e5e5;
  --gray3: #f7f7f7;
  --lightSuccess: #ddfcdc;
  --lightSuccess2: #dafceb;
  --success: #20b16a;
  --success3: #19bc31;
  --success2: #a8e1c8;
  --danger: #d40000;
  --danger1: #ff4343;
  --danger2: #e0a8ae;
  --yellow: #da6900;
  --orange: #ff9500;
  --blue: #0556e5;
  --lightblue: #e1ecfe;
  --lightblue2: #eef2fd;
  --purple: #8543f1;
  --lightpink: #fce2fa;
  --pink: #ed49e0;
  --lightyellow: #fcefe2;
  --black1: #45464e;
  --black2: #383838;
  --black3: #040308;
  --white2: #fafafa;
  --grayII: #e0e0e0;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Work Sans", sans-serif !important;
  scrollbar-width: thin !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.h {
  height: 100% !important;
}
a {
  text-decoration: none !important;
}
/* fonts */

.font-1 {
  font-family: "DM Sans", sans-serif !important;
}
.font-2 {
  font-family: "Work Sans", sans-serif !important;
}

/* General */
button,
input {
  box-shadow: none !important;
  outline: none !important;
}
input:focus {
  outline: none !important;
  border: 1px solid var(--blue) !important;
  /* box-shadow: 0 0 10px #719ECE !important; */
}
.pointer {
  cursor: pointer;
}
/* Text */

.text-primary1 {
  color: var(--primary) !important;
}
.text-1 {
  color: var(--primary) !important;
}
.text-secondary2 {
  color: var(--secondary2) !important;
}
.text-black1 {
  color: var(--black1);
}
.text-black2 {
  color: var(--black2);
}
.text-blacklight {
  color: var(--blacklight);
}

.text-2 {
  color: var(--secondary) !important;
}
.text-yellow {
  color: var(--yellow) !important;
}
.text-blue {
  color: var(--blue) !important;
}
.text-success,
.text-success1 {
  color: var(--success) !important;
}
.text-danger {
  color: var(--danger);
}
.text-purple {
  color: var(--purple);
}
.text-pink {
  color: var(--pink);
}
.black3 {
  color: var(--black3);
}

.danger1 {
  color: var(--danger1);
}
/* Font Weight */

.fw-0 {
  font-weight: 300 !important;
}

.fw-1 {
  font-weight: 400 !important;
}

.fw-2 {
  font-weight: 500 !important;
}

.fw-3 {
  font-weight: 600 !important;
}

input::placeholder {
  padding: 8px;
  margin: 8px;
}

/* Background Color */
.bg-default {
  background-color: transparent !important;
}
.bg-white1 {
  background-color: white !important;
}
.bg-primary1 {
  border: 1px solid var(--primary) !important;
  background-color: var(--primary) !important;
}

.bg-gary {
  background-color: var(--gray);
}
.bg-secondary2 {
  background-color: var(--secondary2);
}
.bg-secondary3 {
  background-color: var(--secondary3) !important;
}
.bg-gary2 {
  background-color: var(--gray2);
}
.bg-gary3 {
  background-color: var(--gray3) !important;
}
.bg-main {
  background-color: var(--primary);
}
.bg-success1 {
  background-color: var(--lightSuccess);
}
.bg-success2 {
  background-color: var(--lightSuccess2);
  border: 1px solid var(--lightSuccess2);
}
.bg-success4 {
  background-color: var(--success3);
  border: 1px solid var(--success3);
}
.bg-white2 {
  background-color: var(--white2);
  border: 1px solid var(--white2);
}
.bg-success3 {
  background-color: var(--success2);
  border: 1px solid var(--success2);
}
.bg-yellow {
  background-color: var(--yellow);
  border: 1px solid var(--yellow);
}
.bg-secondary3 {
  background-color: var(--secondary3);
  border: 1px solid var(--secondary3);
}
.bg-secondary4 {
  background-color: var(--secondary4);
  border: 1px solid var(--secondary4);
}
.bg-orange {
  background-color: var(--orange);
  border: 1px solid var(--orange);
}
.bg-blue {
  background-color: var(--blue);
  border: 1px solid var(--blue);
}
.bg-danger1 {
  background-color: var(--danger1);
  border: 1px solid var(--danger1);
}
.bg-danger2 {
  background-color: var(--danger2);
  border: 1px solid var(--danger2);
}
.bg-lightblue {
  background-color: var(--lightblue) !important;
  border: 1px solid var(--lightblue) !important;
}
.bg-yellow2 {
  background-color: #fff;
  border: 1px solid var(--yellow);
}
.bg-red {
  background-color: var(--danger);
  border: 1px solid var(--danger);
}
.bg-lightblue2 {
  background-color: var(--lightblue2);
  border: 1px solid var(--lightblue2);
}
.bg-lightpink {
  background-color: var(--lightpink);
  border: 1px solid var(--lightpink);
}
.bg-lightyellow {
  background-color: var(--lightyellow);
  border: 1px solid var(--lightyellow);
}
.bg-border-danger {
  border: 1px solid var(--danger);
  background: #fff;
  padding: 6px;
}
.bg-black2 {
  background-color: var(--black2) !important;
}
.bg-grayII {
  background-color: var(--grayII);
}
.padding-4 {
  padding: 6px;
}
/* Font Size */
.h10 {
  font-size: 40px !important;
}
.h30 {
  font-size: 20px;
}
.h41 {
  font-size: 15px;
}
.h40 {
  font-size: 18px !important;
}
.h50 {
  font-size: 14px !important;
}
.h60 {
  font-size: 12px !important;
}
/* hrs */
.small hr:not([size]),
.hrless {
  height: 0.4px !important;
}

/* Card Design */
.logo1 {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 35px;
}
.logo2 {
  position: absolute;
  left: 0;
  bottom: 0;
}
.logo3 {
  position: absolute;
  right: 0;
  bottom: 0;
}
/* reChart */
.recharts-surface {
  width: 100% !important;
}
/* IMage */
.imageWrapper {
  width: 136px;
  height: 136px;
  bottom: -38px;
  left: 30px;
}
.imageWrapper2 {
  width: 100px;
  height: 100px;
  /* bottom: -38px;
    left: 30px; */
}
.search .input-group-text {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.innerBorder {
  border: 4px solid #20b16a;
}
.imageWrapper img {
  object-fit: cover;
}
.imageWrapper-sm {
  width: 34px;
  height: 34px;
  overflow: hidden;
  border-radius: 50%;
}
.imageWrapper-md {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}

.imageIcon img {
  width: 24px !important;
  height: 24px !important;
  object-fit: cover;
}
.curve {
  border-radius: 50%;
}

/* Table */
.Containertable tr,
.Containertable td {
  border: 1px solid;
  font-size: 14px !important;
  padding: 5px 0;
}
td:nth-child(1) {
  width: 40%;
  text-align: left;
  border: 1px solid black;
}
td:nth-child(2) {
  width: 60%;
  text-align: left;
  border: 1px solid black;
}
/* Phone*/
.PhoneInputInput {
  border: none !important;
}

/* Form */
.form-control,
.form-select {
  border: 1px solid #ced4da65 !important;
}

/* fileUpload */
.fileUploadContainer {
  border: 0.2px dashed #00000017 !important;
}
/* SeasCard */
.rounded-4 {
  border-radius: 15px !important ;
}
/* dot */
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f17732;
}

.listMessage {
  overflow-y: scroll;
  height: 400px;
  scrollbar-width: thin !important;
}
.shadow3 {
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.05),
    2px 2px 10px rgba(0, 0, 0, 0.05);
}
.g-6,
.gx-6 {
  --bs-gutter-x: 5rem !important;
}
/* swiper */
.swiper-pagination-bullet {
  background-color: #fc9a08 !important;
}
.swiper-pagination-bullet-active {
  background-color: #510707 !important;
  padding: 0 30px !important;
  border-radius: 10px !important;
}
.swiper-pagination {
  bottom: 35% !important;
}

.sideA {
  height: 130vh;
}

@media only screen and (max-width: 400px) {
  .h30 {
    font-size: 16px;
  }
  .scrollX {
    overflow-x: scroll;
  }
}
@media only screen and (max-width: 500px) {
  .scrollX {
    overflow-x: scroll;
  }
  .Login .h10 {
    font-size: 18px !important;
    padding-top: 2rem;
  }
  .swiper-pagination {
    bottom: 10px !important;
  }
  .sideA {
    height: auto;
  }
}

@media only screen and (max-width: 800px) {
  .scrollXI {
    overflow-x: scroll;
  }
}
@media (min-width: 576px) {
  .modal-dialog .modal-lg {
    max-width: 600px !important;
  }
}

.circleContainer {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #0556e5;
  padding: 2px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgnot {
  width: auto !important;
}

/* Login */
.login1 {
  background-image: url("../public/Images/Login/Rectangle\ 3121.png");
  background-position: center;
  background-size: cover;
}

.down {
  background-image: url("../public/Images/Login/bg.png");
  background-position: center;
  background-size: cover;
}

.input-group {
  border: none !important;
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #8b313a;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
}

.css-qbdosj-Input input {
  border: none !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
